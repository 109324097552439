import { useApi } from "lib/apiFetcher";

export const useGetCoursesByTenantSlug = () =>
  //@ts-ignore
  useApi("get", "/tenants/{slug}/courses");

export const useGetSubdomainTenant = (additionalKey: string) =>
  //@ts-ignore
  useApi("get", "/tenants/{slug}/subdomain", additionalKey);

export const useRequestUpgradeTenant = () =>  useApi("post","/tenants/upgrade");

export const useGetSubdomainData = (additionalKey: string) =>
  //@ts-ignore
  useApi("get", "/tenants/{slug}", additionalKey);

export const useGetCoursesMandatoryByTenantSlug = (additionalKey: string) =>
  useApi("get", "/tenants/{slug}/course-mandatories", additionalKey);

export const useGetDashboardReport = (additionalKey: string) =>
  useApi("post", "/tenants/{slug}/dashboard-report", additionalKey);

export const useGetSectionCustomByTenantSlug = (additionalKey: string) =>
// @ts-ignore
  useApi("get", "/tenants/{slug}/sections", additionalKey);

export const useGetOthersByTenantSlug = () =>
// @ts-ignore
  useApi("get", "/tenants/{slug}/course-others");

// @ts-ignore
export const useGetSectionDetail = () => useApi("get", "/tenants/{slug}/sections/{section}/show");

// @ts-ignore
export const useGetSectionMandatoryDetail = () => useApi("get", "/tenants/{slug}/course-mandatories/{course_mandatory}/show");
// @ts-ignore
export const useGetVillages = () => useApi("get", "/tenants/{slug}/villages");
