import {
  CloudflareOptions,
  isImageResizable,
  resizeImageUrl,
} from "lib/cloudflareImageResize";

export const Image: React.FC<
  CloudflareOptions & React.HTMLProps<HTMLImageElement>
> = (props) => {
  const srcSet =
    props.src && isImageResizable(props.src)
      ? [1, 2, 3]
          .map(
            (i) =>
              `${resizeImageUrl(props.src, {
                width: props.width * i,
                height: props.height * i,
              })} ${i}x`
          )
          .join(", ")
      : null;
  if (!props.src) return <div {...props} />;

  return (
    // @ts-ignore
    <img
      // loading="lazy"
      {...props}
      srcSet={srcSet}
      src={
        props.src && (props.width || props.height)
          ? resizeImageUrl(props.src, {
              width: props.width,
              height: props.height,
            })
          : // Allow non gokampus link to be shown for business image
            props.src
      }
    />
  );
};
