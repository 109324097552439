import React, { ReactNode, useContext, useState } from "react";
import IconClose from "../Icon/IconClose";
import { useIsDesktop } from "lib/device";
import { useIsSubdomain, useIsTenant } from "lib/host";
import { motion } from "framer-motion";
import { useModalStore } from "./modalStore";

const useOutsideClick = (callback: () => void) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !(ref.current as any)?.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  return ref;
};

export type Props = {
  isShow?: boolean;
  isSearch?: boolean;
  isFullScreen?: boolean;
  isTopBorderTransparent?: boolean;
  contentStyles?: object;
  contentWidth?: number;
  isWidthScreen?: string;
  className?: string;
  childrenClassName?: string;
  contentClassName?: string;
  zIndex?: number;
  title?: string | ReactNode;
  onClose?(): void;
  onClickOutside?(): void;
  children: ReactNode;
  color?: string;
  hFull?: Boolean;
  bottomChildren?: ReactNode;
  customTitleElement?: ReactNode;
};

const Modal: React.FC<Props> = ({
  isShow,
  isSearch = false,
  isFullScreen,
  isTopBorderTransparent,
  className,
  childrenClassName,
  contentClassName,
  zIndex,
  contentStyles,
  contentWidth,
  isWidthScreen,
  title,
  onClose,
  onClickOutside,
  children,
  color = "#19093A",
  hFull = true,
  bottomChildren,
  customTitleElement,
}) => {
  const isDesktop = useIsDesktop();
  const isSubdomainTenant = useIsSubdomain();
  const isTenantURL = useIsTenant();
  const isB2B = isTenantURL;
  const [isClosed, setisClosed] = useState(false);
  // const { openModal } = useModalStore((state) => state);

  const transitionDuration = 700;

  const onCloseModal = () => {
    // openModal(false);
    setisClosed(true);
    setTimeout(() => {
      onClose();
      setisClosed(false);
    }, transitionDuration);
  };

  const ref = useOutsideClick(() => {
    if (onClickOutside) {
      setisClosed(true);
      setTimeout(() => {
        onClickOutside();
        setisClosed(false);
      }, transitionDuration);
    }
  });

  // if (isSearch) {
  //   openModal(isSearch);
  // }

  if (isShow) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={isClosed ? { opacity: 0 } : { opacity: 1 }}
        style={{ zIndex: zIndex || 9999, position: "relative" }}
        transition={{ duration: transitionDuration / 1000 }}
      >
        <div
          className={`fixed inset-0 flex sg-max:items-end ${
            isB2B ? "items-start" : "items-center"
          } justify-center bg-neutral80_to_dark00 ${className || ""}`}
          style={{ zIndex: zIndex || 9999 }}
        >
          <div
            ref={ref}
            className={`relative w-full bg-slated_grey2 pt-16 pb-16 md:max-w-[640px] max-w-screen overflow-auto
              px-16 md:rounded-12 rounded-t-12
              ${contentClassName || ""}`}
            style={{
              maxHeight: isFullScreen ? "none" : "90%",
              maxWidth: isWidthScreen || contentWidth,
              ...contentStyles,
            }}
          >
            {customTitleElement ?? (
              <div
                className={`flex items-center ${
                  isFullScreen
                    ? " border-b border-neutral20_to_dark30 px-16 pb-16"
                    : ""
                } ${isTopBorderTransparent ? " !border-transparent" : ""}`}
              >
                {title && (
                  <h3 className="text-b1 text-neutral-80 font-bold">{title}</h3>
                )}
                {onClose && (
                  <button
                    type="button"
                    className="ml-auto"
                    onClick={onCloseModal}
                  >
                    <IconClose size={20} color={color} />
                  </button>
                )}
              </div>
            )}

            <div className={childrenClassName}>{children}</div>

            {bottomChildren && (
              <div
                className={`flex items-center${
                  isFullScreen ? " border-b border-neutral20_to_dark30" : ""
                }`}
              >
                {bottomChildren}
              </div>
            )}
          </div>
        </div>

        <style>{`
          html, body {
            overflow: hidden;
          }
        `}</style>
      </motion.div>
    );
  }
  return null;
};

export default Modal;
