import React, { ReactNode } from "react";
import Content from "./Content";
import { Route } from "nextjs-routes";
import { ObjectOmit } from "lib/object";

export type Variant =
  | "primary"
  | "secondary"
  | "secondary_transparant"
  | "secondary_white"
  | "secondary_gray"
  | "white"
  | "green"
  | "red"
  | "yellow"
  | "orange"
  | "green_transparant"
  | "white_transparant";
export type Size = "small" | "medium" | "large";
export type BtnState = "active" | "disabled" | "loading";
export type RoundedSize = "small" | "medium" | "large" | "full";
type HtmlBtnProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
type Props = {
  size?: Size;
  variant?: Variant;
  state?: BtnState;
  rounded?: RoundedSize;
  className?: string;
  path?: Route | string;
  isOutside?: boolean;
  isFull?: boolean;
  children: ReactNode;
};

export const styles: {
  base: string;
  size: { [s in Size]: string };
  rounded: { [r in RoundedSize]: string };
} & {
  [v in Variant]: {
    [v in BtnState]: string;
  };
} = {
  base: "border-2 items-center inline-flex justify-center hover:no-underline",
  size: {
    // large: "h-48 text-b1 font-bold px-18",
    // update height button large
    large: "h-40 text-b1 font-bold px-18",
    medium: "h-36 text-b2 font-medium px-16",
    small: "h-24 text-c1 font-medium px-14",
  },
  primary: {
    active: "bg-purple-50 border-purple-50 text-white_to_white",
    disabled:
      "bg-neutral20_to_dark30 border-neutral20_to_dark30 text-neutral-40 cursor-not-allowed",
    loading: "bg-purple-50 border-purple-50",
  },
  secondary: {
    active: "bg-white border-purple-50 hover:bg-purple-05 !text-purple-50",
    disabled:
      "bg-neutral-10 border-neutral-40 text-neutral-40 cursor-not-allowed",
    loading: "bg-white border-purple-50 !text-purple-50",
  },
  secondary_transparant: {
    active: "bg-transparent border-purple-50 !text-purple-50",
    disabled:
      "bg-neutral-10 border-neutral-40 text-neutral-40 cursor-not-allowed",
    loading: "bg-transparent border-purple-50 !text-purple-50",
  },
  secondary_white: {
    active: "bg-transparent border-white !text-white",
    disabled:
      "bg-transparent border-white !text-white opacity-30 cursor-not-allowed",
    loading: "bg-transparent border-white !text-white",
  },
  secondary_gray: {
    active: "bg-transparent border-neutral-40 text-neutral-80",
    disabled:
      "bg-transparent border-neutral-40 text-neutral-80 opacity-30 cursor-not-allowed",
    loading: "bg-transparent border-neutral-40 text-neutral-80",
  },
  green_transparant: {
    active: "bg-transparent border-none text-green-70",
    disabled: "text-neutral-40 cursor-not-allowed",
    loading: "bg-transparent text-green-70",
  },
  white: {
    active: "bg-white_to_white border-white_to_dark20 text-black_to_black",
    disabled:
      "bg-neutral-10 border-neutral-10 text-neutral-40 cursor-not-allowed",
    loading: "bg-white_to_white border-white_to_dark20 text-black_to_black",
  },
  white_transparant: {
    active: "bg-transparent border-white text-white",
    disabled: "text-neutral-40 cursor-not-allowed",
    loading: "bg-transparent border-white text-white",
  },
  green: {
    active: "bg-green-10 border-green-70 text-green-70",
    disabled:
      "bg-neutral-20 border-neutral20_to_dark30 text-neutral-40 cursor-not-allowed",
    loading: "bg-green-10 border-green-70",
  },
  red: {
    active: "bg-red-10 border-red-70 text-red-70",
    disabled:
      "bg-neutral-20 border-neutral20_to_dark30 text-neutral-40 cursor-not-allowed",
    loading: "bg-red-10 border-red-70",
  },
  orange: {
    active: "bg-orange-50 border-orange-50 text-white",
    disabled:
      "bg-neutral-20 border-neutral20_to_dark30 text-neutral-40 cursor-not-allowed",
    loading: "bg-orange-10 border-orange-50",
  },
  yellow: {
    active: "bg-yellow-10 border-yellow-70 text-yellow-70",
    disabled:
      "bg-neutral-20 border-neutral20_to_dark30 text-neutral-40 cursor-not-allowed",
    loading: "bg-yellow-10 border-yellow-70",
  },
  rounded: {
    small: "rounded-sm",
    medium: "rounded-8",
    large: "rounded-lg",
    full: "rounded-full",
  },
};

const Button: React.FC<Props & HtmlBtnProps> = (props) => {
  const {
    size = "medium",
    variant = "primary",
    type = "button",
    state = "active",
    rounded = "medium",
    className = "",
    isFull,
    children,
  } = props;
  const roundedClass = styles["rounded"][rounded];
  const btnStyles = `${styles.base} ${styles.size[size]} ${
    styles[variant][state]
  } ${roundedClass}${isFull ? " w-full" : ""} ${className || ""}`;

  return (
    <button
      {...ObjectOmit(props, [
        "size",
        "variant",
        "type",
        "state",
        "rounded",
        "className",
        "isFull",
        "children",
      ])}
      type={type}
      disabled={state === "disabled" || state === "loading"}
      className={btnStyles}
    >
      <Content state={state} variant={variant}>
        {children}
      </Content>
    </button>
  );
};

export default Button;
